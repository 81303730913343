import { h } from "preact";
import renderToString from "preact-render-to-string";
import { useEffect, useRef } from "preact/compat";
import { initPopoverElement, disposePopover } from "../utils/Popover";
function PreactPopover(props) {
    const popoverRef = useRef(null);
    const { trigger, dismissBtnClass, popoverClass, container, placement, templateContent, content, target, title, className, children, } = props;
    const mountPopover = (initPopoverElement) => {
        if (!popoverRef.current)
            return;
        initPopoverElement(popoverRef.current);
    };
    const unmountPopover = (disposePopover) => {
        if (!popoverRef.current)
            return;
        disposePopover(popoverRef.current);
    };
    useEffect(() => {
        mountPopover(initPopoverElement);
        return () => {
            unmountPopover(disposePopover);
        };
    }, []);
    return (h("button", { type: "button", ref: popoverRef, class: className, "data-bs-title": title, "data-target": target, "data-template-content": templateContent, "data-bs-content": content ? renderToString(content) : null, "data-bs-placement": placement, "data-container": container, "data-bs-custom-class": popoverClass, "data-dismiss-btn": dismissBtnClass, "data-bs-trigger": trigger }, children));
}
export default PreactPopover;

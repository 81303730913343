import { makeAutoObservable } from "mobx";
import { t, translate } from "../../I18n";
import { getAbbrMonthName, getDayName } from "./utils";
export class PFulfillmentTime {
    constructor(params) {
        this.domain = params.domain;
        this.modal = params.modal;
        this._isTouched = false;
        makeAutoObservable(this);
    }
    get isVisible() {
        return this.domain.hasAnyAvailableTime;
    }
    get isEditable() {
        return !(this.domain.isOnlyAsap && this.domain.value !== null);
    }
    get isTouched() {
        return this._isTouched;
    }
    get hasValue() {
        return this.domain.value !== null;
    }
    get formattedValue() {
        var _a;
        switch ((_a = this.domain.value) === null || _a === void 0 ? void 0 : _a.type) {
            case "ASAP":
                return {
                    title: translate(t.orders.form.asap),
                    description: translate(t.orders.form.asap_description),
                };
            case "OnTime":
                return {
                    title: `${getDayName(this.domain.value.date)}${this.domain.value.date.isToday
                        ? ""
                        : `, ${this.domain.value.date.format("D")} ${getAbbrMonthName(this.domain.value.date)}`}, ${this.domain.value.date.fullHour}`,
                };
            default:
                return { title: translate(t.orders.form.choose_date) };
        }
    }
    get formattedValueShort() {
        var _a;
        switch ((_a = this.domain.value) === null || _a === void 0 ? void 0 : _a.type) {
            case "ASAP":
                return translate(t.theme_defaults.hours.now);
            case "OnTime":
                return this.domain.value.date.isToday
                    ? this.domain.value.date.fullHour
                    : this.domain.value.date.format("DD.MM, HH:mm");
            default:
                return translate(t.restaurants.widgets.menu.plan_date);
        }
    }
    touch() {
        this._isTouched = true;
    }
    get hasAnyAvailableTime() {
        return this.domain.hasAnyAvailableTime;
    }
    get isAvailableAtSelectedTime() {
        return this.domain.isAvailableAtSelectedTime;
    }
}

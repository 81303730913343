import { h, Fragment } from "preact";
import { t, translate } from "restaumatic-client";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useRef } from "preact/hooks";
import Device from "../../utils/Device";
import PreactModal, { ModalHeader } from "../../components/PreactModal";
import Alert from "../../components/Alert";
import { RadioFieldOption } from "../../components/RadioField";
import { hideModal } from "../../utils/Modals";
function FulfillmentTimeModal(props) {
    const { id, dataFieldId, modal, onShow, onSubmit, onHidden } = props;
    const themeName = Skubacz.configuration.theme_name;
    const paginationItemsCount = getPaginationItemsCount(themeName);
    const daysSliderRef = useRef(null);
    const hoursListRef = useRef(null);
    const handleSubmit = () => {
        modal.save();
        if (modal.isSaved) {
            void hideModal(id).then(() => onSubmit());
        }
    };
    const handleShow = () => {
        runInAction(() => {
            const isMobile = Device.screen.isMobileSize();
            modal.initialize({
                navigationType: isMobile ? "InfiniteScroll" : "Pagination",
                navigationPageSize: isMobile ? 14 : paginationItemsCount,
            });
        });
        onShow();
    };
    const hasNavigation = !modal.isTodayOnly;
    const navigationType = modal.navigationType;
    return (h(PreactModal, { id: id, modalClass: themeName !== "active_menu"
            ? "modal-dialog--full-height modal-dialog--scrollable"
            : "", dataFieldId: dataFieldId, onShow: handleShow, onHidden: onHidden },
        h(ModalHeader, { title: modal.title }),
        h("div", { className: "modal-body u-pt0" },
            h("article", { className: "m-fulfillment-time" },
                hasNavigation && navigationType === "InfiniteScroll" && (h(InfiniteDaysSlider, { modal: modal, listRef: hoursListRef })),
                hasNavigation && navigationType === "Pagination" && (h(PaginationDaysSlider, { modal: modal, sliderRef: daysSliderRef, listRef: hoursListRef, itemsTargetCount: paginationItemsCount })),
                h(HoursList, { modal: modal, listRef: hoursListRef }))),
        h("div", { className: "modal-footer modal-footer--sticky" },
            h("button", { type: "button", onClick: handleSubmit, className: "btn btn-primary btn-block", "data-field-id": "fulfillmentTime.submit", disabled: !modal.isValid }, translate(t.actions.save)))));
}
const PaginationDaysSlider = observer((props) => {
    const { modal, sliderRef, listRef, itemsTargetCount } = props;
    return (h("div", { id: "fulfillment-time-days-slider", className: "m-fulfillment-time__slider m-fulfillment-time__slider--pagination u-sticky-top", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_date) },
        modal.hasPrevItems && (h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--prev u-btn-unstyled", onClick: () => {
                var _a, _b;
                modal.getPrev();
                (_b = (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector("button")) === null || _b === void 0 ? void 0 : _b.focus();
            }, "aria-label": translate(t.views.pagination.previous), "aria-controls": "fulfillment-time-days-slider" },
            h("i", { className: "icon-btn icon-left", "aria-hidden": "true" }))),
        h("ul", { ref: sliderRef, className: "m-fulfillment-time__slider-content u-list-unstyled", "aria-orientation": "horizontal" }, modal.dateItems.map((dateItem) => (h("li", { className: `m-fulfillment-time__slider-item ${modal.dateItems.length < itemsTargetCount ? "u-flex-grow-0" : ""}` },
            h("button", { type: "button", onClick: () => {
                    var _a;
                    modal.setActiveDate(dateItem);
                    (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
                }, className: `m-fulfillment-time__day u-btn-unstyled ${modal.isActiveDate(dateItem) ? "is-active" : ""} ${modal.isDisabledItem(dateItem) ? "is-disabled" : ""}` },
                h("span", { className: "u-text-bold u-mb1" }, dateItem.title),
                h("span", { className: "u-text-nowrap" },
                    dateItem.description,
                    dateItem.type === "Conflicting" && (h("i", { className: "icon-btn icon-alert-sign text-warning u-ml1", "aria-hidden": "true" })))))))),
        modal.hasNextItems && (h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--next u-btn-unstyled", onClick: () => {
                var _a, _b;
                modal.getNext();
                (_b = (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector("button")) === null || _b === void 0 ? void 0 : _b.focus();
            }, "aria-label": translate(t.views.pagination.next), "aria-controls": "fulfillment-time-days-slider" },
            h("i", { className: "icon-btn icon-right", "aria-hidden": "true" })))));
});
const InfiniteDaysSlider = observer((props) => {
    const { modal, listRef } = props;
    return (h("div", { id: "fulfillment-time-days-slider", className: "m-fulfillment-time__slider m-fulfillment-time__slider--infinite u-sticky-top", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_date) },
        h("ul", { className: "m-fulfillment-time__slider-content u-list-unstyled", "aria-orientation": "horizontal" },
            modal.hasPrevItems && (h("li", { className: "align-self-center" },
                h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--prev u-btn-unstyled", onClick: () => {
                        modal.getPrev();
                    }, "aria-label": translate(t.views.pagination.previous), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { className: "icon-btn icon-left", "aria-hidden": "true" })))),
            modal.dateItems.map((dateItem) => (h("li", { className: "m-fulfillment-time__slider-item" },
                h("button", { type: "button", onClick: () => {
                        var _a;
                        modal.setActiveDate(dateItem);
                        (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
                    }, className: `m-fulfillment-time__day u-btn-unstyled ${modal.isActiveDate(dateItem) ? "is-active" : ""} ${modal.isDisabledItem(dateItem) ? "is-disabled" : ""}` },
                    h("span", { className: "u-text-bold u-mb1" }, dateItem.title),
                    h("span", { className: "u-text-nowrap" },
                        dateItem.description,
                        dateItem.type === "Conflicting" && (h("i", { className: "icon-btn icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))))))),
            modal.hasNextItems && (h("li", { className: "align-self-center" },
                h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--next u-btn-unstyled", onClick: () => {
                        modal.getNext();
                    }, "aria-label": translate(t.views.pagination.next), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { className: "icon-btn icon-right", "aria-hidden": "true" })))))));
});
const HoursList = observer((props) => {
    const { modal, listRef } = props;
    // Scroll to selected hour item on first render (when modal shown)
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            setTimeout(() => {
                selectedHourItem.scrollIntoView({
                    behavior: "instant",
                    block: "center",
                });
            }, 150); // 0.15s is a hardcoded value in boostrap 3 styles for modal fade transition duration
        }
    }, [listRef]);
    // Scroll to selected hour item when active date change
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            selectedHourItem.scrollIntoView({
                behavior: "instant",
                block: "center",
            });
        }
    }, [listRef, modal.activeDate]);
    return (h("ul", { ref: listRef, className: "m-fulfillment-time__list u-list-unstyled", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_time), tabIndex: -1, "data-field-id": "fulfillmentTime.value", "data-field-type": "radioGroup" },
        modal.dateTimeItems.map((item) => (h("li", { "data-field-id": "fulfillmentTime." + item.stringValue },
            h(RadioFieldOption, { name: "fulfillment-time-hour", value: item.stringValue, label: h(Fragment, null,
                    item.title,
                    item.type === "Conflicting" && (h("i", { className: "icon-base icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))), isActive: modal.isActiveValue(item), isDisabled: modal.isDisabledItem(item), childrenCssClass: "m-radio__children--align-to-label", onChange: () => modal.setValue(item.value), onBlur: () => null },
                h(Fragment, null,
                    item.description !== undefined && (h("span", null, item.description)),
                    item.type === "Conflicting" && (h(Alert, { type: "warning", extraClasses: "u-my0", message: h(Fragment, null,
                            h("p", null, translate(t.orders.form.conflicting_products_warning)),
                            h("ul", null, item.conflictingProducts.map((productName) => (h("li", null, productName))))) }))))))),
        modal.dateTimeItems.length === 0 && (h(Alert, { type: "danger", message: translate(t.orders.form.unavailable_date) }))));
});
function getPaginationItemsCount(themeName) {
    switch (themeName) {
        case "minimax":
        case "behemot":
        case "pasja":
        case "new_vision":
        case "fiesta":
        case "vesspa":
            return 4;
        default:
            return 5;
    }
}
export default observer(FulfillmentTimeModal);

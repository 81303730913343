import { makeAutoObservable } from "mobx";
import { RDay, } from "../../api/orderingHoursWidget";
import { translate, t } from "../../I18n";
import { PFulfillmentTimeNavigation } from "./PFulfillmentTimeNavigation";
import { getDayName, getItemsFirstDate, getItemsLastDate } from "./utils";
export class PFulfillmentTimeModal {
    constructor(params) {
        this.domain = params.domain;
        this.restoreValueOnInit = params.restoreValueOnInit;
        this.navigation = null;
        this._dateItems = [];
        this._dateTimeItems = [];
        this._value = null;
        this._activeDate = "";
        this._isSaved = false;
        makeAutoObservable(this);
    }
    initialize(params) {
        this._value = this.restoreValueOnInit ? this.domain.value : null;
        this.navigation =
            params.navigationType === "Pagination"
                ? PFulfillmentTimeNavigation.createPaginated({
                    pageSize: params.navigationPageSize,
                    getDates: (from, to) => this.getDates(from, to),
                    minDay: this.domain.minDay,
                    maxDay: this.domain.maxDay,
                })
                : PFulfillmentTimeNavigation.createInfinityScroll({
                    pageSize: params.navigationPageSize,
                    getDates: (from, to) => this.getDates(from, to),
                    minDay: this.domain.minDay,
                    maxDay: this.domain.maxDay,
                });
        // NOTICE: Pagination temp. improvement (temp. because Pagination probably will be removed):
        // Get first available dayItems page and try to set active date from domain value.
        // This should prevent from displaying "left" pagination arrow in the most often cases where prev page has only ~1 item,
        // Example: "Tomorrow" is active, we are displaying "prev" button and allow user to go to the prev page with only "Today" item.
        // We would like to show dayItems from "Today" and select "Tomorrow" as active.
        if (this.navigation.type === "Pagination") {
            const initDateItems = this.navigation.getInit(this.domain.minDay);
            const activeDay = this.valueDay || this.domain.minDay;
            const activeDateItem = initDateItems.find((dateItem) => {
                return "date" in dateItem && dateItem.date.isSame(activeDay);
            });
            if (activeDateItem) {
                this._dateItems = initDateItems;
                this.setActiveDate(activeDateItem);
            }
            else {
                // Simply get dayItems from active (always good for InfinityScroll navigation)
                this._dateItems = this.navigation.getInit(activeDay);
                this.setActiveDate(this._dateItems[0]);
            }
        }
        else {
            this._dateItems = this.navigation.getInit(this.valueDay || this.domain.minDay);
            this.setActiveDate(this._dateItems[0]);
        }
    }
    get title() {
        return this.domain.isDelivery
            ? translate(t.order.delivery_time)
            : translate(t.order.pickup_time);
    }
    get isTodayOnly() {
        return (this.domain.isSameDayOnly &&
            this._dateItems.every((dateItem) => "date" in dateItem && dateItem.date.isToday));
    }
    get isValid() {
        return this._value !== null;
    }
    get isSaved() {
        return this._isSaved;
    }
    get navigationType() {
        var _a;
        return (_a = this.navigation) === null || _a === void 0 ? void 0 : _a.type;
    }
    get dateItems() {
        return this.formatDateItems(this._dateItems);
    }
    get dateTimeItems() {
        return this.formatDateTimeItems(this._dateTimeItems);
    }
    get activeDate() {
        return this._activeDate;
    }
    get hasPrevItems() {
        const firstDate = getItemsFirstDate(this._dateItems);
        return this.domain.hasPrevious(firstDate);
    }
    get hasNextItems() {
        const lastDate = getItemsLastDate(this._dateItems);
        return this.domain.hasNext(lastDate);
    }
    get valueDay() {
        if (this._value === null) {
            return null;
        }
        switch (this._value.type) {
            case "ASAP":
                return this.domain.today;
            case "OnTime":
                return RDay.fromRDate(this._value.date);
        }
    }
    getNext() {
        if (this.navigation === null) {
            throw new Error("Trying to get next page before initializing (setting navigation strategy)");
        }
        this._dateItems = this.navigation.getNext(this._dateItems);
    }
    getPrev() {
        if (this.navigation === null) {
            throw new Error("Trying to get next page before initializing (setting navigation strategy)");
        }
        this._dateItems = this.navigation.getPrev(this._dateItems);
    }
    setActiveDate(dateItem) {
        switch (dateItem.type) {
            case "Available":
            case "Conflicting":
                this._activeDate = dateItem.date.toString();
                this._dateTimeItems = dateItem.items;
                this._value = this.findDateTimeValue(dateItem.items, this._value);
                break;
            case "Disabled":
                this._activeDate = dateItem.from.toString();
                this._dateTimeItems = [];
                this._value = null;
                break;
        }
    }
    isActiveDate(item) {
        switch (item.type) {
            case "Available":
            case "Conflicting":
                return item.date.toString() === this._activeDate;
            case "Disabled":
                // TODO: is between from & to
                return item.from.toString() === this._activeDate;
        }
    }
    setValue(value) {
        this._value = value;
    }
    isActiveValue(item) {
        if (this._value === null) {
            return false;
        }
        if (this._value.type === "ASAP" && item.value.type === "ASAP") {
            return true;
        }
        return (this._value.type === "OnTime" &&
            item.value.type === "OnTime" &&
            this._value.date.eq(item.value.date));
    }
    isDisabledItem(item) {
        return item.type === "Disabled";
    }
    save() {
        if (this.isValid) {
            this._isSaved = true;
            this.domain.setValue(this._value);
        }
    }
    getDates(from, to) {
        const domainItems = this.domain.getDates(from, to);
        return domainItems.map((domainItem) => {
            switch (domainItem.type) {
                case "Available":
                case "Conflicting":
                    return domainItem;
                case "Disabled":
                    return {
                        type: "Disabled",
                        from: domainItem.date,
                        to: domainItem.date,
                    };
            }
        });
    }
    // TODO: move to utils?
    findDateTimeValue(items, currentValue) {
        var _a, _b, _c, _d;
        if (currentValue !== null && currentValue.type === "ASAP") {
            return ((_b = (_a = items.find((item) => item.type !== "Disabled" && item.value.type === "ASAP")) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null);
        }
        if (currentValue !== null && currentValue.type === "OnTime") {
            const currentHour = currentValue.date.fullHour;
            return ((_d = (_c = items.find((item) => item.type !== "Disabled" &&
                item.value.type === "OnTime" &&
                item.value.date.fullHour === currentHour)) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null);
        }
        return null;
    }
    /**
     * Adds UI-related data eg. formatted strings
     */
    formatDateItems(dateItems) {
        return dateItems.map((dateItem) => {
            switch (dateItem.type) {
                case "Available":
                case "Conflicting":
                    return {
                        title: getDayName(dateItem.date, true),
                        description: dateItem.date.toDayAndMonthString(),
                        ...dateItem,
                    };
                case "Disabled": {
                    const isSingleDay = dateItem.from.toString() === dateItem.to.toString();
                    return {
                        title: isSingleDay
                            ? dateItem.from.isToday || dateItem.from.isTomorrow
                                ? getDayName(dateItem.from, true)
                                : dateItem.from.toDayAndMonthString()
                            : `${dateItem.from.toDayAndMonthString()}-${dateItem.to.toDayAndMonthString()}`,
                        description: translate(t.orders.form.unavailable),
                        ...dateItem,
                    };
                }
            }
        });
    }
    /**
     * Adds UI-related data eg. formatted strings
     */
    formatDateTimeItems(dateTimeItems) {
        return dateTimeItems.map((dateTimeItem) => {
            switch (dateTimeItem.value.type) {
                case "ASAP":
                    return {
                        title: translate(t.orders.form.asap),
                        description: translate(t.orders.form.asap_description),
                        stringValue: "ASAP",
                        ...dateTimeItem,
                    };
                case "OnTime": {
                    const hourString = dateTimeItem.value.date.format("HH:mm");
                    return {
                        title: hourString,
                        description: undefined,
                        stringValue: hourString,
                        ...dateTimeItem,
                    };
                }
            }
        });
    }
}

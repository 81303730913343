import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import isTodayPlugin from "dayjs/plugin/isToday";
import isTomorrowPlugin from "dayjs/plugin/isTomorrow";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";
dayjs.extend(isBetweenPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(isTomorrowPlugin);
dayjs.extend(customParseFormat);
export default dayjs;

import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import { useEffect } from "preact/hooks";
import { CREATOR_MODAL_ID } from "./init";
import CreatorHeader from "./CreatorHeader";
import CreatorBody from "./CreatorBody";
import CreatorFooter from "./CreatorFooter";
import { FulfillmentSettingsTracking, t, Tracking, translate, } from "restaumatic-client";
import FulfillmentTimeModal from "../Menu/FulfillmentTimeModal";
import { showModal, switchModals } from "../../utils/Modals";
function Creator(props) {
    const { productCard } = props;
    const { creator } = productCard;
    const fulfillmentTimeModalId = "creator-time";
    const submitLabel = getSubmitLabel(creator.shouldChangeFulfillmentTime, creator.noFulfillmentTime);
    useEffect(() => {
        Tracking.trackEvent({
            tag: "VisitPage",
            contents: {
                page: {
                    tag: "ProductCard",
                    productIds: creator.productTypeIdValues,
                },
            },
        });
    }, [creator.productTypeIdValues]);
    const handleSubmit = () => {
        if (!creator.isSubmitDisabled && creator.shouldChangeFulfillmentTime) {
            void switchModals(CREATOR_MODAL_ID, fulfillmentTimeModalId);
        }
        else {
            productCard.addToCart();
        }
    };
    const handleTimeSubmit = () => {
        productCard.addToCart();
        FulfillmentSettingsTracking.chooseDateEvent({
            tag: "ProductCard",
            productIds: creator.productTypeIdValues,
        });
    };
    const handleTimeHidden = () => {
        if (!creator.fulfillmentTime.modal.isSaved) {
            void showModal(CREATOR_MODAL_ID);
        }
    };
    return (h(Fragment, null,
        h(CreatorHeader, { photoUrn: creator.product.photo }),
        h(CreatorBody, { creator: creator }),
        h(CreatorFooter, { price: creator.price, submitLabel: submitLabel, isSubmitVisible: creator.isSubmitVisible, isSubmitDisabled: creator.isSubmitDisabled, onSubmit: handleSubmit }),
        h(FulfillmentTimeModal, { id: fulfillmentTimeModalId, dataFieldId: "Creator.fulfillmentTimeModal", modal: creator.fulfillmentTime.modal, onShow: () => creator.fulfillmentTime.touch(), onSubmit: handleTimeSubmit, onHidden: handleTimeHidden })));
}
function getSubmitLabel(shouldChangeFulfillmentTime, noFulfillmentTime) {
    if (shouldChangeFulfillmentTime && noFulfillmentTime) {
        return translate(t.restaurants.widgets.menu.plan_date);
    }
    if (shouldChangeFulfillmentTime && !noFulfillmentTime) {
        return translate(t.restaurants.widgets.menu.change_date);
    }
    return translate(t.theme_defaults.creator.add_to_cart);
}
export default observer(Creator);

import App from "./components/App";
import Store from "./services/Store";
import testLocalStorage from "./utils/testLocalStorage";
import initCartFabAttention from "./utils/initCartFabAttention";
import { init as initRating } from "./utils/Rating";
import CookieConsent from "./utils/CookieConsent";
import * as GroupToggle from "./components/GroupToggle";

// SSR components
import * as SSR from "./ssr";

const client = {
  /**
   * Init App component with global store.
   */
  initApp: function () {
    const initialState = {};
    const appStore = Store.create(App.init(initialState), App.update);

    appStore.subscribe(() => {
      App.view(appStore.getState(), appStore.dispatch);
    });

    appStore.dispatch({ type: "INIT" });
  },

  /**
   * Execute immediately without waiting for DOM.
   */
  executeImmediately: function () {
    GroupToggle.utils.updateIdAttr();

    // replace with empty function, so we don't log errors after unload
    window.onbeforeunload = function () {
      window.onerror = function (message, url, line_number) {};
    };
  },

  initMenuPage: function () {
    if (document.querySelector("[data-cart]") !== null) {
      import(/* webpackChunkName: "MenuPage" */ "./views/MenuPage").then(
        (mod) => mod["default"].init()
      );
    }
  },

  initCheckout: function () {
    if (document.querySelector(".ps-checkout")) {
      import(/* webpackChunkName: "Checkout" */ "./views/Checkout/init").then(
        (checkoutPage) =>
          checkoutPage.init(document.querySelector(".ps-checkout"))
      );
    }
  },

  initOrderInfo: function () {
    if (document.querySelector(".ps-order-info")) {
      import(
        /* webpackChunkName: "ThankYouPage" */ "./views/ThankYouPage/init"
      ).then((thankYouPage) =>
        thankYouPage.init(document.querySelector(".ps-order-info"))
      );
    }
  },

  initReservation: function () {
    if (document.getElementById("ps-reservation")) {
      import(
        /* webpackChunkName: "Reservation" */ "./components/ReservationWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  initConsents: function () {
    if (document.getElementById("ps-consents")) {
      import(
        /* webpackChunkName: "Consents" */ "./components/ConsentsWrapper"
      ).then((mod) => mod["default"].init());
    }
  },

  /**
   * Init cookie warning
   */
  initCookieConsent: function () {
    CookieConsent.init();
  },

  initDeliveryForm: () => {
    const deliveryModule = document.querySelector(".js-delivery-module");
    if (deliveryModule) {
      import(
        /* webpackChunkName: "DeliveryForm" */ "../../output/Components.DeliveryForm/index.js"
      ).then((mod) =>
        mod.init({
          element: deliveryModule,
          country: Skubacz.configuration.country,
          accountId: Skubacz.configuration.account_id,
        })
      );
    }
  },
};

client.executeImmediately();

window.Skubacz.Store = Store;

// Exports for tests.
// Should probably be moved to their own entry point.
window.Skubacz.loadTestExports = () =>
  import(
    /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Site.Components.Creator/index.js"
  )
    .then((MenuV2Creator) => {
      window.Skubacz.MenuV2Creator = MenuV2Creator;
    })
    .then(() =>
      import(
        /* webpackChunkName: "Creator" */ "../../output/Restaumatic.Menu.V2.JS/index.js"
      )
    )
    .then((Encoding) => {
      window.Skubacz.Encoding = Encoding;
    });

function init() {
  testLocalStorage();
  client.initCookieConsent();
  client.initApp();
  initRating();
  client.initMenuPage();
  client.initCheckout();
  client.initOrderInfo();
  client.initReservation();
  client.initConsents();
  client.initDeliveryForm();
  initCartFabAttention();
}

document.addEventListener("DOMContentLoaded", () => {
  if (window.__pageInitHook__) {
    window.__pageInitHook__().then(init);
  } else {
    init();
  }
});

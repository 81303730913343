import { Asap, OrderingHoursWidget, ProductInstanceName, } from "../../api/orderingHoursWidget";
import { NextOrderingTime } from "../../api/orderingHoursWidget/NextOrderingTime";
import { makeImmutable } from "../../core";
import { PostCode } from "../../form";
import { PFulfillmentTime, PFulfillmentTimeModal, } from "../../presentation";
import { OrderingHoursAvailability } from "../Availability/OrderingHoursAvailability";
import { Consents } from "../Consents";
import { ContactDetails, } from "../ContactDetails";
import { Delivery } from "../Delivery";
import { ApartmentInfo, DefaultApartmentInfo, DeliveryAddress, DeliveryAddressStatus, } from "../DeliveryAddress";
import { DEFAULT_FULFILLMENT_TYPE, FulfillmentInstructions, } from "../FulfillmentInstructions";
import { FulfillmentTime, } from "../FulfillmentTime";
import { InvoiceRequest } from "../Invoice";
import { NewFulfillmentTime } from "../NewFulfillmentTime";
import { OrderReceiver } from "../OrderReceiver";
import { PurchaseOrderScope } from "../ParameterScope";
import { Payment } from "../Payment";
import { PaymentAddress } from "../PaymentAddress";
import { ProductInstanceCreator } from "../ProductInstanceCreator";
import { PromotionScheme } from "../PromotionScheme";
import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder";
import { PurchaseOrderPayload } from "../PurchaseOrderPayload";
import { StreetAddress } from "../StreetAddress";
import { TipInstance } from "../TipInstance";
import { WeeklySchedule } from "../WeeklySchedule";
import { FulfillmentTimeValidator } from "./FulfillmentTimeValidator";
export class PurchaseOrderFactory {
    constructor(params) {
        this.country = params.country;
        this.salesChannel = params.salesChannel;
        this.validatePhoneService = params.validatePhoneService;
        this.geocodingService = params.geocodingService;
        this.isQrWaiterOrder = params.isQrWaiterOrder;
        makeImmutable(this);
    }
    createPayload(params) {
        const preorderSettingsWhenEmpty = params.menu.preorderSettings;
        if (params.cartStorageData === null) {
            return PurchaseOrderPayload.empty({
                menu: params.menu,
                preorderSettingsWhenEmpty,
            });
        }
        const uninitializedPayload = PurchaseOrderPayload.fromStorageData({
            menu: params.menu,
            storageData: params.cartStorageData.purchaseOrderPayload,
            preorderSettingsWhenEmpty,
        });
        return uninitializedPayload.mapProductInstances((productInstance) => {
            const productInstanceCreator = ProductInstanceCreator.create({
                productCategories: params.menu.productCategories,
                productTypes: params.menu.productTypes,
                productInstance,
            });
            productInstanceCreator.initWithScope(params.purchaseOrderScope);
            return productInstanceCreator.instance;
        });
    }
    create(params) {
        var _a, _b, _c;
        if (params.cartStorageData !== null &&
            params.cartStorageData.restaurantId !== params.restaurant.id.value) {
            console.warn("Cart restaurant id deserialization mismatch");
        }
        const orderReceiver = new OrderReceiver({
            invoiceRequest: params.invoiceRequestStorageData === null
                ? null
                : InvoiceRequest.fromStorageData(params.invoiceRequestStorageData),
            contactDetails: params.contactDetailsStorageData === null
                ? ContactDetails.empty({
                    isQrWaiterOrder: this.isQrWaiterOrder,
                    validatePhoneService: this.validatePhoneService,
                    contactDetailsLock: params.contactDetailsLock,
                })
                : ContactDetails.fromStorageData({
                    validatePhoneService: this.validatePhoneService,
                    storageData: params.contactDetailsStorageData,
                    contactDetailsLock: params.contactDetailsLock,
                }),
            consents: Consents.fromStorageData(params.consentsStorageData),
        });
        const streetAddress = StreetAddress.fromStorageData({
            country: this.country,
            storageData: params.streetAddressStorageData,
        });
        const apartmentInfo = ApartmentInfo.fromStorageData({
            country: this.country,
            storageData: params.apartmentInfoStorageData,
        });
        const deliveryAddressStatus = DeliveryAddressStatus.create({
            streetAddress,
            restaurantId: params.restaurant.id,
            geocodingService: this.geocodingService,
        });
        const deliveryAddress = new DeliveryAddress({
            streetAddress,
            apartmentInfo,
            status: deliveryAddressStatus,
        });
        const fulfillmentTimeFromStorage = params.fulfillmentTimeStorageData === null
            ? null
            : FulfillmentTime.fromStorageData({
                now: params.now,
                storageData: params.fulfillmentTimeStorageData,
            });
        const fulfillmentTime = this.createFulfillmentTime({
            enableDelayedDelivery: params.enableDelayedDelivery,
            allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
            menuPreorderSettings: params.restaurant.menu.preorderSettings,
            fulfillmentTimeFromStorage,
            now: params.now,
            orderingHours: params.orderingHours,
        });
        const fulfillmentMethodType = (_a = params.fulfillmentMethodTypeStorageData) !== null && _a !== void 0 ? _a : DEFAULT_FULFILLMENT_TYPE;
        const fulfillmentInstructions = FulfillmentInstructions.create({
            restaurant: params.restaurant,
            dineInLocation: params.dineInLocation,
            deliveryAddress,
            fulfillmentTime,
            fulfillmentMethodType,
        });
        const delivery = new Delivery({
            restaurantId: params.restaurant.id,
            defaultMinOrderValue: params.restaurant.minOrderValue.default,
            deliveryAddress,
        });
        const paymentAddress = new PaymentAddress({
            fulfillmentInstructions,
            streetAddress: StreetAddress.empty(this.country),
            apartmentInfo: DefaultApartmentInfo.empty(),
            postCode: PostCode.empty(),
        });
        const payment = Payment.fromStorageData({
            restaurant: params.restaurant,
            fulfillmentInstructions,
            paymentAddress,
            storageData: params.paymentStorageData,
        });
        const purchaseOrderScope = new PurchaseOrderScope({
            restaurant: params.restaurant,
            salesChannel: this.salesChannel,
            fulfillmentInstructions,
            now: params.now,
        });
        const payload = this.createPayload({
            menu: params.restaurant.menu,
            cartStorageData: params.cartStorageData,
            purchaseOrderScope,
        });
        const restaurant = params.restaurantParser.parse({
            domainRestaurant: params.restaurant,
            input: params.menuResponse,
            purchaseOrderScope,
            promotionTypes: params.promotionTypes,
            purchaseOrderPayload: payload,
            franchiseName: params.franchiseName,
            sectionVisibilityService: params.sectionVisibilityService,
        });
        const promotionScheme = params.cartStorageData === null
            ? PromotionScheme.empty({
                inCartPromotionTypes: params.inCartPromotionTypes,
                purchaseOrderPayload: payload,
            })
            : PromotionScheme.fromStorageData({
                inCartPromotionTypes: params.inCartPromotionTypes,
                purchaseOrderPayload: payload,
                storageData: params.cartStorageData.promotionScheme,
            });
        promotionScheme.setScope(purchaseOrderScope);
        const tip = TipInstance.fromStorageData({
            areTipsEnabled: params.restaurant.tips.enabled,
            value: (_c = (_b = params.cartStorageData) === null || _b === void 0 ? void 0 : _b.tip) !== null && _c !== void 0 ? _c : null,
        });
        const orderingHoursWidget = new OrderingHoursWidget({
            orderingHours: params.orderingHours,
            asap: new Asap({
                orderingHours: params.orderingHours,
                scope: purchaseOrderScope,
                allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
                enableDelayedDelivery: params.enableDelayedDelivery,
            }),
            enableDelayedDelivery: params.enableDelayedDelivery,
            allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
            scope: purchaseOrderScope,
            purchaseOrderPayload: payload,
            productInstanceName: new ProductInstanceName({
                productTypes: restaurant.productTypes,
            }),
        });
        const nextOrderingTime = new NextOrderingTime({
            orderingHours: params.orderingHours,
            scope: purchaseOrderScope,
        });
        const newFulfillmentTime = new NewFulfillmentTime({
            scope: purchaseOrderScope,
            orderingHoursWidget,
            fulfillmentInstructions: fulfillmentInstructions,
            weeklyScheduleProvider: {
                get weeklySchedule() {
                    return WeeklySchedule.Full;
                },
            },
            preorderSettingsProvider: params.restaurant.menu,
        });
        const pFulfillmentTime = new PFulfillmentTime({
            domain: newFulfillmentTime,
            modal: new PFulfillmentTimeModal({
                domain: newFulfillmentTime,
                restoreValueOnInit: true,
            }),
        });
        const orderingHoursAvailability = new OrderingHoursAvailability({
            scope: purchaseOrderScope,
            orderingHours: params.orderingHours,
            allowOrderingInClosedRestaurant: params.restaurant.menu.allowOrderingInClosedRestaurant,
            fulfillmentTime: newFulfillmentTime,
        });
        const purchaseOrder = new PurchaseOrder({
            restaurant: params.restaurant,
            orderingAvailability: params.orderingAvailability,
            origin: this.salesChannel,
            payload,
            orderReceiver,
            delivery,
            payment,
            fulfillmentInstructions,
            promotionScheme,
            tip,
            orderingHoursAvailability,
            scope: purchaseOrderScope,
        });
        return {
            purchaseOrder,
            purchaseOrderScope,
            orderingHoursWidget,
            pFulfillmentTime,
            nextOrderingTime,
            restaurant,
        };
    }
    createFulfillmentTime(params) {
        const validator = new FulfillmentTimeValidator({
            orderingHours: params.orderingHours,
            now: params.now,
            enableDelayedDelivery: params.enableDelayedDelivery,
            preorderSettings: params.menuPreorderSettings,
            allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
        });
        return validator.validate(params.fulfillmentTimeFromStorage);
    }
}
